import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import "./terms-of-use.scss";

const TermsOfUse = () => {
  return (
    <HcpLayout pageClass="terms-of-use" noStickyHCPLocatorCTA={true}>
      {/* <div className="top-heading">
        <div className="centered-content">
          <h2>Terms of Use</h2>
        </div>
      </div> */}
      <section className="terms-of-use__content inner-first">
        <div className="centered-content">
          <h1 className="font-40">Terms of Use</h1>
          <h2>Last updated: May 2020 </h2>
          <p>
            We encourage you to read these Terms of Use in their entirety before
            using the site. By accessing and using the site or its services, you
            agree to these Terms of Use and to the collection and use of
            information in accordance with our Privacy Policy, without
            limitation or qualification.
          </p>
          <p>
            Harmony Biosciences (“Harmony”) has provided this website and its
            content for general informational purposes only, for US residents.
            Harmony will undertake reasonable efforts to ensure that all
            information added to this site is accurate at the time such content
            is made available. Please note, however, that Harmony makes no
            warranties or representations that the information is accurate and
            accepts no liability for any errors or omissions in the content of
            the site or any losses or damage that may arise from relying on this
            information. The information may be changed by Harmony at any time.
          </p>
          <h3>Use of Materials and Intellectual Property Rights </h3>
          <p>
            This site and its services contain content specifically provided by
            Harmony or its partners. Such content is protected by copyrights,
            trademarks, and/or other proprietary rights and laws. Users of this
            site shall abide by all copyright notices, information, and
            restrictions contained in any such content. The user shall not sell,
            license, rent, modify, distribute, copy, reproduce, transmit,
            publicly display, publicly perform, publish, adapt, edit, create
            derivative works from, or otherwise exploit any content or
            third-party submissions or other proprietary rights not owned by the
            user, (i) without the consent of the respective owners or other
            valid right, and (ii) in any way that violates any third-party
            right.
          </p>
          <p>
            Harmony authorizes you to copy materials published on this website
            for noncommercial use only, provided that any copy of these
            materials which you make retains all copyright and other proprietary
            notices.
          </p>
          <h3>Links To and From Third-Party Websites</h3>
          <p>
            The website may from time to time contain <nobr>in-text</nobr> links
            to third-party websites, and third-party websites may provide links
            to this site. Harmony is not responsible for, and accepts no
            liability in respect of, any information or opinion contained on any
            third-party site. As Harmony has no control over such sites and
            because their content is subject to change without notice to
            Harmony, it is the responsibility of the user of the site to ensure
            that any information accessed is accurate and that software which is
            downloaded from that site is free of viruses or any other items of a
            destructive nature. Harmony accepts no liability whatsoever in
            respect of any loss, damage, costs or liability incurred through any
            such downloaded material.
          </p>
          <h3>
            Information Does Not Substitute for Care by a Health Professional{" "}
          </h3>
          <p>
            The information on the site is intended only to provide knowledge of
            disease information. This information should not be considered
            complete and should not be used in place of a visit, call,
            consultation, or advice of your physician or other healthcare
            provider. Harmony does not recommend the self-management of health
            problems. Should you have any healthcare-related questions, please
            call or see your physician or other healthcare provider promptly.
            You should never disregard medical advice or delay in seeking it
            because of something you have read here. Information is not
            necessarily accurate, complete, or current.
          </p>
          <h3>Information Accessed on the Website Is Not Exhaustive</h3>
          <p>
            Harmony makes reasonable efforts to include accurate and current
            information, wherever possible, but makes no warranties or
            representations as to its accuracy or completeness. This information
            is provided "as is" without warranty of any kind, either express or
            implied, including but not limited to implied warranty of fitness
            for a particular purpose. Harmony is not responsible for computer
            damage resulting from using the website.{" "}
          </p>
          <h3>Site Access </h3>
          <p>
            Harmony makes no representation or warranty that access to the
            website will be available on a timely basis, will be uninterrupted,
            or will be error-free. Harmony does not warrant that these pages, or
            the server that makes them available, are free of viruses or other
            harmful elements. In no event shall Harmony, its affiliates and
            subsidiaries, and their respective officers, directors, employees,
            agents, representatives, information providers, and licensors and
            their respective heirs and assigns, be liable for any direct,
            indirect, incidental, consequential, exemplary, special, punitive,
            or other damages even if informed of the possibility of such
            damages. The above exclusion may not apply in jurisdictions to the
            extent that they do not allow the exclusion of implied warranties.
            Any personally identifiable information in electronic communications
            to this website is governed by Harmony's Privacy Policy. Harmony
            shall be free to use or copy all other information in any such
            communications, including any ideas, inventions, concepts,
            techniques or know-how disclosed therein, for any purposes,
            including disclosure to third parties and/or developing,
            manufacturing and/or marketing products or services.{" "}
          </p>
          <h3>Changes to Terms of Use </h3>
          <p>
            Harmony may modify these Terms of Use at any time without notice.
            Harmony may terminate these Terms of Use, terminate user access to
            all or any part of this site or any content or any service, or
            suspend any individual user's access to all or any part of this site
            or any content or any service, at any time, without notice, if we
            believe in our sole judgment that the user has breached or may
            breach any term or condition of this agreement, or for our
            convenience. The user may terminate these Terms of Use at any time
            by destroying all materials received from this site (including
            content) and ceasing to use this site and its content and services.
            Harmony reserves the right to remove any content or services from
            the site at any time.
          </p>
          <h3>Agreement to Terms of Use </h3>
          <p>
            By accessing and using the site or its services, you agree to these
            Terms of Use and to the collection and use of information in
            accordance with our{" "}
            <a href="/privacy-policy" title="Read more about our Privacy Policy" className="blue">
              Privacy Policy
            </a>
            , without limitation or qualification. Except as expressly provided
            in a particular "legal notice" on this site, these Terms of Use
            constitute the entire agreement between the user and Harmony with
            respect to the use of this site and its content and services.{" "}
          </p>
          <p>
            All materials and information appearing on this site are intended
            for US residents only. These Terms of Use were last updated: May
            2020.
          </p>
        </div>
      </section>
    </HcpLayout>
  );
};

export default TermsOfUse;

export const Head = () => (
  <Seo
    title="Terms of Use | WAKIX® (pitolisant)"
    description="View our terms of use here."
  />
);
